@charset 'UTF-8';

.modal {
    .shortcuts-modal {
        margin-top: 50px;
        width: 1100px;

        .shortcuts-content {
            .modal-header {
                background: transparent;
                border: none;
                color: inherit;
                padding: 20px 40px 20px;

                .close {
                    color: inherit;
                    font-size: 28px;
                    font-weight: normal;
                    overflow: hidden;
                    right: 35px;
                    top: 35px;
                }

                .modal-title {
                    color: inherit;
                    font-size: 20px;

                    >div {
                        &:first-child {
                            margin-bottom: 2.5em;
                        }
                    }
                }
            }
        }

        .modal-body {
            max-height: calc(100vh - 67px);
            padding: 0 40px 20px;
        }

        .section {
            >div {
                &:first-child {
                    margin-bottom: 2.5em;
                }
            }

            .shortcut-line {
                margin: 17px 0;

                span {
                    &:first-child {
                        margin-right: 5px;
                    }
                }

                .shortcut-key {
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 5px 0 5px 5px;
                    padding: 1px 5px;
                }
            }
        }

        .section-title {
            font-size: 18px;
            margin: 1.5em 0;
        }

        .subsection {
            border-left: 2px solid;
            padding-left: 15px;
        }

        .info__label {
            margin: 1rem 0;
            text-align: center;
        }
    }
}

.shortcut-line {
    margin: 16px 0;

    span {
        &:first-child {
            margin-right: 5px;
        }
    }

    .shortcut-key {
        border-radius: 3px;
        font-size: .9em;
        font-weight: 500;
        margin: 5px 0 5px 5px;
        padding: 1px 5px;
    }
}

@media (max-height: 800px) {
    .modal {
        .shortcuts-modal {
            .section-title {
                margin-top: 0;
            }

            .info__label {
                margin-top: 0;
            }
        }
    }

    .shortcut-line {
        margin-top: 14px;
    }
}
