.backstage {
    height: 100vh;
    width: 100vw;
}

.backstage-navbar {
    background: $white;
    height: 41px;
    border-bottom: 1px solid $light-gray;
    padding: 10px 20px;
    z-index: 10;
}

.backstage-navbar__back {
    color: inherit;
    text-decoration: none;

    .fa {
        font-size: 1.1em;
        font-weight: bold;
        margin-right: 7px;
    }

    &:hover,
    &:active {
        color: inherit;
    }
}

.backstage-body {
    background-color: $bg--gray;
    bottom: 0;
    display: inline-block;
    height: calc(100vh - 41px);
    overflow: auto;
    top: 0;
    width: 100%;
}

.backstage-content {
    background-color: $bg--gray;
    margin: 46px auto;
    max-width: 1200px;
    padding-left: 135px;
    vertical-align: top;
}

.backstage-sidebar {
    padding: 46px 20px;
    position: absolute;
    vertical-align: top;
    width: 260px;

    ul {
        list-style: none;
        padding: 0;
    }

    > ul {
        @include clearfix;
        @include border-radius(2px);
        border-left: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
    }
}

.backstage-sidebar__category {

    + .backstage-sidebar__category {
        .category-title {
            border-top: none;
        }
    }

    .category-title {
        border-bottom: 1px solid $light-gray;
        border-top: 1px solid $light-gray;
        color: $black;
        display: block;
        line-height: 35px;
        padding: 0 10px;
        position: relative;

        .fa {
            @include opacity(.5);
            position: relative;
            text-align: center;
            top: 1px;
            width: 20px;
        }

        .fa-smile-o {
            font-size: 17px;
        }
    }

    .category-title--active {
        background-color: $primary-color;
        color: $white;

        .fa {
            @include opacity(1);
        }
    }

    .category-title__text {
        left: 2.5em;
        position: absolute;
    }

    .sections {
        background: $white;
        border-bottom: 1px solid $light-gray;
    }

    .section-title,
    .subsection-title {
        display: block;
        font-size: .95em;
        line-height: 29px;
        padding-left: 2em;
        text-decoration: none;
    }

    .subsection-title {
        padding-left: 3em;
    }

    .section-title--active,
    .subsection-title--active {
        background-color: $primary-color;
        color: $white;
        font-weight: 600;
    }
}

.backstage-header__divider {
    color: $gray;
    margin: 0 10px;
}

.backstage-header {
    @include clearfix;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    h1 {
        font-size: 20px;
        margin: 5px 0;
    }
}

.backstage-filters {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.backstage-filters__sort {
    flex-grow: 1;
    flex-shrink: 0;
    line-height: 30px;

    .filter-sort {
        text-decoration: none;

        &.filter-sort--active {
            color: inherit;
            cursor: default;
        }
    }

    .divider {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.backstage-filter__search {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    width: 270px;

    .fa {
        @include opacity(.4);
        left: 11px;
        position: absolute;
        top: 11px;
    }

    input {
        background: $white;
        padding-left: 30px;
    }
}

.backstage-list {
    background-color: $white;
    border: 1px solid $light-gray;
    padding: 5px 15px;
    min-height: 50px;
}

.backstage-list__help {
    display: block;
    margin: 1em 0;
}

.backstage-list__item {
    border-bottom: 1px solid $light-gray;
    padding: 20px 15px;
    position: relative;

    &:last-child {
        border: none;
    }

    .item-details {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .item-details__row + .item-details__row {
        @include clearfix;
        text-overflow: ellipsis;
    }

    .item-details__trigger {
        margin-left: 6px;
    }

    .item-details__description,
    .item-details__content_type,
    .item-details__token,
    .item-details__trigger-words,
    .item-details__trigger-when,
    .item-details__url,
    .item-details__creation {
        display: inline-block;
        margin-top: 10px;
        vertical-align: top;

        &:empty {
            display: none;
        }
    }

    .item-details__trigger-words {
        white-space: nowrap;
    }
}

// Backstage Form

.backstage-form {
    background-color: $white;
    border: 1px solid $light-gray;
    padding: 40px 30px 30px;
    position: relative;

    &.backstage-form__confirmation {
        padding: 30px 30px 20px;
    }

    label {
        font-weight: normal;
    }

    .backstage-form__title {
        margin: 5px 0 1.5em;
    }

    .radio,
    .checkbox {
        input {
            height: 16px;
            margin: 0 5px 0 0;
            position: relative;
            top: 2px;
            width: 16px;
        }
    }

    .form-control {
        background: $white;

        &:focus {
            border-color: $primary-color;
        }
    }

    .form__help {
        color: $dark-gray;
        font-size: .95em;
        margin-top: 1em;

        & + .form__help {
            margin-top: .4em;
        }
    }
}

.backstage-form__footer {
    border-top: 1px solid $light-gray;
    margin-top: 2.5em;
    padding-top: 1.8em;
    text-align: right;
    overflow: hidden;

    .has-error {
        float: left;
        margin: 0;
        max-width: 50%;

        .control-label {
            text-align: left;
            padding: 0;
        }
    }
}

.integration__icon {
    position: absolute;
    height: 80px;
    width: 80px;
    right: 20px;

    &.integration-list__icon {
        top: 50px;
    }
}

.integrations-list {

}

.integration-option {
    @include single-transition(all, .2s, ease);
    @include border-radius(4px);
    background-color: $white;
    border: 1px solid $light-gray;
    flex: 0 0 30%;
    margin: 0 30px 30px 0;
    padding: 20px;
    text-align: center;

    &:hover {
        @extend %box-shadow-1;
        color: default;
        text-decoration: none;
    }
}

.integration-option__image {
    height: 80px;
    margin: .5em 0 .7em;
}

.integration-option__title {
    color: $black;
    margin-bottom: 10px;
}

.integration-option__description {
    color: $dark-gray;
}

.emoji-list__table {
    width: 100%;

    .backstage-list__item {
        display: table-row;
    }

    .backstage-list__empty td {
        padding: 15px 20px;
    }
}

.emoji-list__table-header {
    font-weight: bold;
}

.emoji-list__name {
    padding: 20px 0 20px 15px;
    width: 30%;
}

.emoji-list__image {
    padding: 15px 0;
    width: 15%;
}

.emoji-list__creator {
    padding: 15px 0;
    width: 40%;
}

.emoji-list__actions {
    padding: 20px 15px 20px 0;
    width: 15%;
}

.add-emoji__upload {
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;

    input {
        @include opacity(0);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
    }
}

.add-emoji__filename,
.add-emoji__preview {
    padding-top: 7px;
}

.bot-list {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 12px;
    line-height: 24px;
}

.bot-list__item {
    background: alpha-color($black, 0.02);
    padding: 8px 12px;
    font-size: 13px;

    &:first-child {
        border: none;
    }

    &:hover {
        background: alpha-color($black, 0.06);
    }

    &.alert {
        margin: 0;
        padding: 12px;
        border-radius: 0;
    }

    .form-horizontal {
        .control-label {
            padding-top: 4px;
        }
    }
}

.bot-details__description {
    margin: 4px 0;
}

.bot-disabled {
    width: 100%;
    height: 11px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
        padding: 0 10px;
        opacity: 0.9;
        color: #000000;
        background-color: #FFFFFF;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
    }
}

.bot-list-img {
    width: 32px;
    height: 32px;
}

.bot-list-img-container {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    float: left;
    margin-right: 15px;
    flex: 0 0 32px;
}

.bot-img {
    width: 129px;
    height: 129px;
}

.bot-img-container {
    width: 129px;
    height: 129px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 50%;
}

.bot-profile__remove {
    @include border-radius(50%);
    @include single-transition(all, .15s, ease);
    background: $black;
    color: $white;
    height: 26px;
    position: absolute;
    left: 110px;
    text-decoration: none;
    top: 4px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 22px;
    }
}

.bot-profile__section {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 1em;
    margin-bottom: 1em;
}

.bot-list__disabled {
    .item-details {
        color: rgba($black, .6);
    }

    .bot-list {
        opacity: .6;
    }
}

.bot-token-has-error {
    color: #a94442;
}
