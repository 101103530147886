@charset "UTF-8";

.search-bar__container {
    .channel-header__links {
        .sidebar--right & {
            line-height: 25px;
        }
    }
}

.icon--rotate {
    @include animation(spin .7s infinite linear);
}

.search-item-snippet {
    @include clearfix;
    text-overflow: ellipsis;

    ul,
    ol {
        padding-left: 20px;
    }
}

.search__font-icon {
    pointer-events: none;
    color: var(--center-channel-color-56);
    left: 4px;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.search__icon {
    pointer-events: none;
    fill: v(center-channel-color-56);
    left: 9px;
    position: absolute;
    top: 6px;

    svg {
        width: 14px;
        height: 14px;

        body.enable-animations & {
            @include single-transition(opacity, $transition-quick, ease-out);
        }
    }
}

.input-clear {
    cursor: pointer;
    font-size: 16px;
    color: v(center-channel-color-56);
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 2.4rem;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: v(center-channel-color-72);
    }

    &:active {
        color: v(center-channel-color);
    }

    &.visible {
        visibility: visible;
    }

    body.enable-animations & {
        @include single-transition(opacity, $transition-quick, ease-out);
    }
}

.search-bar__container {
    padding: 0 4px 0 8px;
}

.search__form {
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;

    .search-bar__container & {
        @include border-radius(4px);
        border: 1px solid v(center-channel-color-24);
        margin: 16px 0 0;
        width: 180px;
        height: 32px;

        .input-clear {
            right: 4px;
        }

        &:hover {
            border: 1px solid v(center-channel-color-40);
        }

        &--focused,
        &--focused:hover {
            border: 2px solid v(button-bg);
            padding: 0;

            .search__icon {
                top: 6px;
                left: 8px;
            }

            .input-clear {
                right: 3px;
            }
        }
    }

    body.enable-animations .search-bar__container & {
        @include transition-property(border-color, width);
        @include transition-duration($transition-quick, 0.3s);
        @include transition-delay(0s);
        @include transition-timing-function(ease-in-out, ease-out);
    }

    .inner-wrap.move--left & {
        width: 272px;
    }

    .search-bar {
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 14px;
        height: 28px;
        outline: none;
        padding: 0 2.8rem 2px 3rem;
        width: 100%;

        &.a11y--focused {
            box-shadow: none;
        }
    }

    .LoadingSpinner {
        @include opacity(0.5);
        font-size: 1em;
        position: absolute;
        right: 25px;
        top: 4px;
    }
}

.search-items-container {
    @include flex(1 1 auto);
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 56px);
    position: relative;
    padding-top: 28px;

    &.no-results {
        display: flex;
        padding-top: 0;
    }
}

.search-items-container div.loading {
    text-align: center;
}

.search-items-container img {
    display: inline-block;
}

.search-results-header {
    border-bottom: $border-gray;
    color: #999999;
    font-size: 1em;
    font-weight: 400;
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    text-transform: uppercase;
}

.search-item__container {
    .post {
        margin: 0;
        padding: 0 1em 16px;

        &:first-child {
            border: none;
        }

        &:hover {
            .post__header {
                padding-right: 105px;
            }
        }

        .post__header {
            padding-right: 70px;
        }

        .search-channel__name {
            font-weight: 600;
            padding: 16px 0 12px;
        }

        .search-channel__archived {
            opacity: 0.5;
            float: right;
        }

        .post-pre-header__icons-container {
            padding-right: 10px; // If the padding of post__img changes, this needs to be adjusted accordingly
            width: 53px; // If the width of post__img changes, this needs to be adjusted accordingly
            margin-left: 0; // if left margin of post__content changes, this needs to be adjusted accordingly
        }
    }
}

.col__controls {
    font-size: 13px;
    position: absolute;
    right: 0;
    top: -9px;

    a {
        vertical-align: top;
    }


    .search-item__jump {
        @include border-radius(4px);
        font-size: 12px;
        position: relative;
        padding: 5px 4px 0;
        text-decoration: none;
        color: v(center-channel-color-40);
        font-weight: 600;
    }
}

.search-item-time {
    @include opacity(.7);
    font-size: .9em;
}

.search-results-none {
    padding: 10px;
}

.search-highlight {
    background-color: $yellow;
}

.search-hint__title {
    padding: 22px 24px;
    font-weight: bold;
    border-bottom: 1px solid var(--center-channel-color-16);
    margin: 0;
}

.search-hint__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 8px 0;
}

.search-hint__suggestions-list__option {
    padding: 10px 24px;
    display: flex;
    justify-content: left;
    text-align: left;
    color: var(--center-channel-color);
    cursor: pointer;
    box-sizing: border-box;

    &.highlighted {
        background: rgba(var(--center-channel-color-rgb), 0.08);
    }
}

.search-hint__suggestion-list__flex-wrap {
    width: 51px;
}

.search-hint__suggestion-list__label {
    text-transform: uppercase;
    background: rgba(var(--center-channel-color-rgb), 0.16);
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.01em;
    border-radius: 4px;
    padding: 1px 5px;
}

.search-hint__suggestion-list__value {
    margin-left: 16px;
}

.sidebar--right__subheader {
    // Fighting specificity with _sidebar-right.scss (sidebar--right .sidebar--right__subheader ul)
    ul.search-hint__suggestions-list {
        padding: 10px 0;
    }

    .search-hint__suggestions-list__option:first-child {
        padding-top: 10px;
    }

    .search-hint__suggestions-list__option:hover {
        background: rgba(var(--center-channel-color-rgb), 0.08);
    }
}
