@charset "UTF-8";

.reacticon {
    display: inline-block;
    fill: inherit;
    opacity: .7;
    position: relative;
    top: 2px;
    vertical-align: middle;
    visibility: hidden;
}

.emoticon {
    border: none !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    align-items: end;
    justify-content: space-between;
    height: 21px;
    min-height: 21px;
    min-width: 21px;
    vertical-align: middle;
    width: 21px;

    &--unicode {
        font-size: 21px;

        .os--windows & {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &:not(.emoticon--unicode) {
        color: transparent;

        &::selection{
            background-color: transparent;
            color: transparent;
        }
    }
}

.emoticon-suggestion {
    @include clearfix;
    cursor: pointer;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 100%;

    > div {
        &:first-child {
            text-align: center;
            width: 30px;
        }
    }
}

.emoticon-suggestion__image {
    margin-top: -2px;
    max-height: 20px;
    max-width: 20px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

.emoji-picker {
    @include border-radius($border-rad);
    @include clearfix;
    @include user-select(none);
    border-radius: 3px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    margin-right: 3px;
    position: absolute;
    width: 315px;
    z-index: 1100;

    &.emoji-picker--single {
        .emoji-picker__header {
            position: relative;
        }
    }

    .browser--ie & {
        width: 325px;
    }

    .app__content & {
        margin-right: 0;
    }

    &.bottom {
        margin-top: 10px;
    }

    .nav-tabs {
        margin-bottom: 0px;
        flex: 0 0 34px;
        display: flex;
        justify-content: flex-end;

        li {
            flex: 1;

            a {
                border: none !important;
                border-bottom: 2px solid transparent !important;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                opacity: 0.7;
                outline: none;
                height: 34px;
                margin-left: 5px;
                margin-right: 5px;
                padding-top: 9px;
            }
        }

        li.active {
            a {
                opacity: 1;
                height: 34px;
                margin-left: 5px;
                margin-right: 5px;
                padding-top: 9px;
            }
        }

        svg {
            top: 2px;
        }
    }
}

.emoji-picker__categories {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;

    .emoji-picker__category {
        color: v(center-channel-color-48);
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        text-decoration: none;

        &:hover {
            background-color: rgba(var(--center-channel-color-rgb), 0.04);
            color: v(center-channel-color);
        }

        &:active {
            background-color: rgba(var(--center-channel-color-rgb), 0.16);
            color: v(center-channel-color);
        }

        &--selected {
            color: v(center-channel-color);
        }

        &.disable {
            pointer-events: none;
        }
    }
}

.emoji-picker__header,
.modal-body .emoji-picker .emoji-picker__header {
    display: none; // only visible < 480px width screen
    padding: 13px 10px 13px 15px;
    background: $primary-color;
    border: 1px solid $light-gray;
    color: $white;
    position: absolute;
    top: 0;
    width: 100%;

    .emoji-picker__header-title {
        margin-top: 2px;
        font-size: 17px;
    }

    .emoji-picker__header-close-button {
        width: 30px;
        height: 30px;
        opacity: 1;
        color: $white;
        background: transparent;

        &:hover {
            background: rgba($black, 0.1);
        }
    }
}

.modal-body .emoji-picker .emoji-picker__header {
    .emoji-picker__header-title {
        margin-top: 0px;
    }
}

.emoji-picker__search-container {
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-radius: 14px;
    margin: 8px 7px 1px 7px;

    .emoji-picker__search-icon {
        padding-left: 9px;
        padding-top: 6px;
        position: absolute;
        font-size: 14px;
    }

    .emoji-picker__search {
        border-width: 0px;
        border-radius: inherit;
        padding: 2px 0 2px 27px;
        width: 100%;
        height: 26px;
        font-size: 13px;

        &:focus{
            outline: none;
        }
    }
}

.emoji-picker__items {
    border-top-style: solid;
    border-top-width: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 8px 8px;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    height: 290px;

    .emoji-picker__container {
        min-height: 100%;
        position: relative;
        width: 100%;
    }

    .emoji-picker__category-header {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 6px;
        margin-top: 3px;
        padding-top: 3px;
    }

    .emoji-picker__preview_sprite {
        display: inline-block;
        height: 45px;
        margin: 3px;
        padding: 3px;
        vertical-align: top;
    }

    .emoji-picker-items__container {
        > span {
            border-radius: 6px;
            border: 0;
            cursor: pointer;
            display: inline-block;
            height: 21px;
            margin: 3px;
            overflow: hidden;
            padding: 0;
            text-align: center;
            vertical-align: top;
            width: 21px;
        }
    }

    .emoji-picker__item-wrapper {
        .emoji-picker__item {
            font-size: 18px;
            height: auto;
            margin: 0 auto;
            max-height: 21px;
            max-width: 21px;
            min-height: 0;
            min-width: 0;
            width: auto;
        }
    }
}

.emoji-picker__item {
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    margin: 0;
    overflow: hidden;
    padding: 2px;
    position: relative;
    vertical-align: middle;
    width: 26px;
    margin: 0 3px 4px;

    &.selected,
    &:hover {
        background-color: rgba(var(--center-channel-color-rgb), 0.16);
    }

    &:active {
        background-color: rgba(var(--center-channel-color-rgb), 0.32);
    }

    > div {
        text-align: center;
    }

    img {
        position: relative;

        &.emoji-category--custom {
            @include transform(scale(1));
            height: auto;
            left: auto;
            max-height: 22px;
            max-width: 22px;
            top: auto;
            width: auto;
        }
    }
}

.emoji-picker__preview {
    align-items: stretch;
    border-top: 1px solid;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    height: 50px;
    justify-content: flex-start;
    padding: 7px 10px 0;
    position: relative;
    vertical-align: middle;

    &.emoji-picker__preview-placeholder {
        padding: 12px;
    }

    > div {
        display: table-cell;
        vertical-align: middle;
    }

    .emoji-picker__preview-image-label-box {
        margin-top: -3px;
        margin-left: 4px;
        min-width: 0;

        .emoji-picker__preview-name {
            font-size: 13px;
            font-weight: bold;
        }

        .emoji-picker__preview-aliases {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .emoji-picker__preview-image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        max-height: 36px;
        width: 42px;
        max-width: 42px;
        text-align: center;

        .sprite-preview {
            border: none;
            display: inline-block;
            height: 36px;
            padding: 0;
            vertical-align: middle;
            width: 36px;
        }
    }

    .emoji-picker__preview-image {
        max-height: 36px;
        max-width: 42px;
    }
}
