@charset 'UTF-8';

b,
strong {
    font-weight: 600;
}

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:focus,
    &:hover {
        color: $primary-color--hover;
    }
}

.color--link {
    color: $link-color;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

body {
    @include font-smoothing;
    font-family: 'Open Sans', sans-serif;
}

.is-empty:empty {
    display: none;
}

label {
    &.has-error {
        font-weight: normal;
        color: $red;
    }
}

.small {
    font-size: 12px;
}

.light {
    @include opacity(.56);
}

.word-break--all {
    word-break: break-all;
}

.whitespace--nowrap {
    white-space: nowrap;
}

.whitespace--normal {
    white-space: normal;
}

.overflow--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

ul,
ol {
    margin-bottom: .11em;
    margin-top: 3px;
    padding-left: 22px;
}

.fa {
    &.fa-1x {
        font-size: 1.6rem;
    }
}

.font-weight--normal {
    font-weight: normal;
}

.font-weight--semibold {
    font-weight: 600;
}

.cursor--pointer {
    cursor: pointer;
}

/* open-sans-300 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-300italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-regular - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-600 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-600italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.eot');
    /* IE9 Compat Modes */
    src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

.app__body.font--open_sans {
    font-family: 'Open Sans', sans-serif;
}

.color--link--adminack {
    color: $white;
    padding-left: 20px;
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}