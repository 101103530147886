@charset 'UTF-8';

.no-results__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    text-align: center;
    word-break: break-word;
    max-width: 500px;
    margin: auto;
}

.no-results__variant-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: rgba(var(--center-channel-color-rgb), 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px;
}

.no-results__title {
    font-weight: 600;
    font-size: 20px;
    color: var(--center-channel-color);
    margin-bottom: 15px;
}

.no-results__icon {
    display: flex;

    svg {
        width: 62px;
        height: 62px;
        fill: v(button-bg);
    }
}

.no-results__mini_icon {
    width: 11px;
    margin: 2px;
    vertical-align: middle;
}